<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div class="title-container">
                <ion-row class="title-content">
                    <ion-col class="title-text-only">
                        <div class="title-content-avatar">
                            <ion-avatar class="title-content-avatar-image">
                                <img src="@/assets/misc/placeholder.png" v-if="isPageLoading === true">
                                <img :src="picture" v-if="isPageLoading === false">
                            </ion-avatar>
                        </div>
                        <div class="title-content-text">
                            <p class="title-content-subheader"><span class="title-content-notice">My Profile</span></p>
                            <h1 class="title-content-name" v-if="isPageLoading === false">{{firstName}} {{lastName}}</h1>
                            <h1 class="title-content-name" v-if="isPageLoading === true">Loading, please wait....</h1>
                        </div>
                    </ion-col>
                </ion-row>
            </div>
            <div class="content-container" v-if="isPageLoading === false">
                <div class="content-container-subtitle">
                    <h3>Kindly complete the form. This will only happen once</h3>
                </div>
                <ion-card class="content-container-card">
                    <ion-card-header class="content-card-title">
                        <h1>Verify your identity:</h1>
                    </ion-card-header>
                    <ion-card-content>
                        <ion-card-subtitle>Email Address:</ion-card-subtitle>
                        <ion-card-title class="content-card-data">
                            <b>{{emailAddress}}</b>
                        </ion-card-title>
                    </ion-card-content>
                    <ion-card-header>
                        <ion-card-subtitle>Employee Number:</ion-card-subtitle>
                        <ion-card-title class="content-card-data">
                            <ion-input
                            class="onboard-input"
                            :value="employeeNo"
                            @ionInput="employeeNo = $event.target.value;"
                            placeholder="Employee Number"></ion-input>
                        </ion-card-title>
                    </ion-card-header>
                </ion-card>
                <ion-card class="content-container-card">
                    <ion-card-header class="content-card-title">
                        <h1>Fill-out the Basic Information:</h1>
                    </ion-card-header>
                    <ion-card-content>
                        <ion-row class="content-name-container">
                            <ion-col>
                                <ion-card-subtitle>First Name:</ion-card-subtitle>
                                <ion-card-title>
                                    <ion-input
                                    class="onboard-input"
                                    :value="firstName"
                                    @ionInput="firstName = $event.target.value;"
                                    placeholder="First Name"></ion-input>
                                </ion-card-title>
                            </ion-col>
                            <ion-col>
                                <ion-card-subtitle>Last Name:</ion-card-subtitle>
                                <ion-card-title>
                                    <ion-input 
                                    class="onboard-input"
                                    :value="lastName"
                                    @ionInput="lastName = $event.target.value;"
                                    placeholder="Last Name"></ion-input>
                                </ion-card-title>
                            </ion-col>
                        </ion-row>
                        <ion-row class="content-name-container">
                            <ion-col>
                                <ion-card-subtitle>Middle Name:</ion-card-subtitle>
                                <ion-card-title>
                                    <ion-input
                                    class="onboard-input"
                                    :value="middleName"
                                    @ionInput="middleName = $event.target.value;"
                                    placeholder="First Name"></ion-input>
                                </ion-card-title>
                            </ion-col>
                            <ion-col>
                                <ion-card-subtitle>Suffix:</ion-card-subtitle>
                                <ion-card-title>
                                    <ion-input 
                                    class="onboard-input"
                                    :value="suffix"
                                    @ionInput="suffix = $event.target.value;"
                                    placeholder="Suffix"></ion-input>
                                </ion-card-title>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-card-subtitle>Work Category:</ion-card-subtitle>
                                <ion-item class="content-card-select">
                                    <!-- <ion-label>Work Category</ion-label> -->
                                    <ion-select
                                    :value="workCategory"
                                    class="content-card-dropdown"
                                    placeholder="Select your work category"
                                    @ionChange="workCategory = $event.target.value;"
                                    ok-text="Confirm"
                                    cancel-text="Dismiss"
                                    interface="action-sheet">
                                        <ion-select-option value="Work From Home">Work From Home</ion-select-option>
                                        <ion-select-option value="Skeletal - Head Office">Skeletal - Head Office</ion-select-option>
                                        <ion-select-option value="Business as Usual - Branches/Field">Business as Usual - Branches/Field</ion-select-option>
                                    </ion-select>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-card-subtitle>Department:</ion-card-subtitle>
                                <ion-item class="content-card-select">
                                    <!-- <ion-label>Department</ion-label> -->
                                    <ion-select
                                    :value="dept"
                                    class="content-card-dropdown"
                                    placeholder="Select your department"
                                    @ionChange="dept = $event.target.value;"
                                    ok-text="Confirm"
                                    cancel-text="Dismiss"
                                    interface="action-sheet">
                                        <ion-select-option v-for="(item, index) in departments" :key="index">{{item}}</ion-select-option>
                                    </ion-select>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-card-subtitle>Branch:</ion-card-subtitle>
                                <ion-card-title>
                                    <ion-input
                                    class="onboard-input"
                                    :value="branch"
                                    @ionInput="branch = $event.target.value;"
                                    placeholder="Branch Assigned"></ion-input>
                                </ion-card-title>
                            </ion-col>
                        </ion-row>
                    </ion-card-content>
                </ion-card>
                <ion-card class="content-container-card">
                    <ion-card-header>
                        <ion-card-title>
                            <ion-row class="card-action-container">
                            <ion-card-subtitle class="card-action-title">Should you experience any of the symptoms, would you like us to set you up with a teleconsultation with the company physician?</ion-card-subtitle>
                            <ion-card-title>
                                <ion-list>
                                    <ion-radio-group 
                                        class="radio-container"
                                        :value="teleconsultation"
                                        @ionChange="teleconsultation = $event.target.value;"
                                    >
                                        <ion-item class="input-item-custom" lines="none">
                                            <ion-radio class="radio-button" :value="true"></ion-radio>
                                            <ion-label>Yes</ion-label>
                                        </ion-item>
                                        <ion-item class="input-item-custom" lines="none">
                                            <ion-radio class="radio-button" :value="false"></ion-radio>
                                            <ion-label>No</ion-label>
                                        </ion-item>
                                    </ion-radio-group>
                                </ion-list>
                            </ion-card-title>              
                            </ion-row>
                        </ion-card-title>
                    </ion-card-header>
                </ion-card>
                <div class="button-container">
                    <ion-button color="danger" @click="logoutDialog"><b>Logout</b></ion-button>
                    <ion-button @click="presentAlertCheckbox"><b>Save Changes</b></ion-button>
                </div>
            </div>
            <div class="content-container" v-if="isPageLoading === true">
                <ion-card class="content-container-card">
                    <ion-card-content>
                    <ion-row class="message-content-container">
                        <ion-skeleton-text end="end" animated style="width: 80%;"></ion-skeleton-text>
                        <ion-skeleton-text end="end" animated style="width: 60%;"></ion-skeleton-text>
                        <ion-skeleton-text end="end" animated style="width: 80%;"></ion-skeleton-text>
                        <ion-skeleton-text end="end" animated style="width: 60%;"></ion-skeleton-text>
                    </ion-row>
                    </ion-card-content>
                </ion-card>
                <ion-card class="content-container-card">
                    <ion-card-content>
                    <ion-row class="message-content-container">
                        <ion-skeleton-text end="end" animated style="width: 80%;"></ion-skeleton-text>
                        <ion-skeleton-text end="end" animated style="width: 60%;"></ion-skeleton-text>
                        <ion-skeleton-text end="end" animated style="width: 80%;"></ion-skeleton-text>
                        <ion-skeleton-text end="end" animated style="width: 60%;"></ion-skeleton-text>
                    </ion-row>
                    </ion-card-content>
                </ion-card>
                <ion-card class="content-container-card">
                    <ion-card-content>
                    <ion-row class="message-content-container">
                        <ion-skeleton-text end="end" animated style="width: 80%;"></ion-skeleton-text>
                        <ion-skeleton-text end="end" animated style="width: 60%;"></ion-skeleton-text>
                        <ion-skeleton-text end="end" animated style="width: 80%;"></ion-skeleton-text>
                        <ion-skeleton-text end="end" animated style="width: 60%;"></ion-skeleton-text>
                    </ion-row>
                    </ion-card-content>
                </ion-card>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import { 
    alertController, 
    toastController, 
    loadingController,
    IonCardHeader,
    IonContent,
    IonCard,
    IonButton,
    IonAvatar,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonInput,
    IonCol,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonPage
} from '@ionic/vue';

import { mapGetters } from 'vuex';
import store from '../store/index';
import VueCookies from 'vue-cookies';
import CryptoJS from 'crypto-js';

export default {
    name: 'Onboard',
    components: {
        IonCardHeader,
        IonContent,
        IonCard,
        IonButton,
        IonAvatar,
        IonCardSubtitle,
        IonCardTitle,
        IonCardContent,
        IonInput,
        IonCol,
        IonRow,
        IonSelect,
        IonSelectOption,
        IonItem,
        IonPage
    },
    data() {
        return {
            emailAddress: '',
            firstName: '',
            lastName: '',
            middleName: '',
            suffix: '',
            workCategory: '',
            dept: '',
            teleconsultation: false,
            employeeNo: '',
            branch: '',
            userInfo: {},
            picture: "@/assets/misc/placeholder.png",
            departments: [
                'Ops Branches',
                'Ops Head Office',
                'GSD',
                'CS',
                'Marketing',
                'SAM',
                'LSG - Head Office',
                'LSG - Field',
                'ITD',
                'Forex',
                'Global Business Solutions',
                'Finance',
                'AVP',
                'HRD',
                'Audit',
                'Legal and Compliance',
                'Risk Management',
                'Product Management',
                'Office of the President',
                'Digital Business'
            ],
            isPageLoading: true,
        };
    },
    computed: {
        ...mapGetters([
            "login/currUser",
            "login/isAuthenticated"
        ]),
    },
    watch:{
        store: {
            handler() {
            },
            deep: true
        },
    },
    async created () {
        let parsed_user = {};
        let bytes = "";
        let decryptedData = "";

        let cipherData = VueCookies.get('misc');
        
        if (cipherData === null  || cipherData === undefined || cipherData === '') {
			localStorage.removeItem("is_consent_agreed");
			VueCookies.remove('misc');
            VueCookies.remove("misc-hs");
			window.location.href = "/login";
		} else {
            bytes = CryptoJS.AES.decrypt(cipherData, process.env.VUE_APP_DCRPT_KEY);
            decryptedData = bytes.toString(CryptoJS.enc.Utf8);

            if (decryptedData) {
                let decode = JSON.parse(decryptedData);
                let user = decode.user;
                
                //Check if user exists
                if (user) {
                    this.populateData(user);

                    if (user.employee_no === null || user.employee_no === "" || user.employee_no === undefined) {
                        return;        
                    } else {
                        this.$router.push('/dashboard');
                    }
                } else {
                    //Run verify auth if data does not exist
                    await store.dispatch('login/verifyAuth').then(() => {
                        parsed_user = JSON.parse(store.getters['login/currUser']);
                        this.populateData(parsed_user);
                        return;
                    }).catch(err => {
                        if (err.status == 401) {
                            localStorage.setItem('is_session_expired', true);
                            localStorage.removeItem("is_consent_agreed");
                            VueCookies.remove("misc");
                            VueCookies.remove("misc-hs");
                            window.location.href = '/login';
                        }
                    });

                    if (parsed_user.employee_no === null || parsed_user.employee_no === "" || parsed_user.employee_no === undefined) {
                        return;        
                    } else {
                        this.$router.push('/dashboard');
                    }
                }
            } else {
                localStorage.setItem('is_session_expired', true);
                localStorage.removeItem("is_consent_agreed");
                VueCookies.remove("misc");
                VueCookies.remove("misc-hs");
                window.location.href = '/login';
            }
		}
    },
    methods: {
        async presentAlertCheckbox() {
            const alert = await alertController.create({
                cssClass: 'alert-container',
                header: 'Save Changes',
                backdropDismiss: false,
                message: 'Are you sure you want to save the changes?',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                        },
                    },
                    {
                        text: 'Continue',
                        handler: () => {
                            this.updateProfile();
                        },
                    },
                ],
            });

            return alert.present();
        },
        async dismissLoading() {
            const loading = await loadingController;

            loading.dismiss()
        },
        async presentLoading() {
            const loading = await loadingController
                .create({
                cssClass: 'loading-container',
                message: 'Please wait...',
                duration: this.timeout,
                });

            await loading.present();
        },
        async updateProfile() {
            this.presentLoading();

            let cipherData = VueCookies.get('misc-hs');

            let bytes = ''
            let decryptedData = ''

            if (cipherData) {
                bytes = CryptoJS.AES.decrypt(cipherData, process.env.VUE_APP_DCRPT_KEY)
                decryptedData = bytes.toString(CryptoJS.enc.Utf8)
            }

            let payload = {
                id: this.userInfo.id,
                data: {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    middle_name: this.middleName,
                    suffix: this.suffix,
                    email: this.emailAddress,
                    employee_no: this.employeeNo,
                    work_category: this.workCategory,
                    assigned_department: this.dept,
                    enable_teleconsultation: this.teleconsultation,
                    branch: this.branch,
                    status: '1',
                    hs_token: decryptedData
                }
            };

            await store.dispatch('user/updateProfile', payload).then(res => {
                this.dismissLoading();
                const that = this;

                let currData = res.data.user;
        
                let bytes = "";
                let decryptedData = "";

                let cipherData = VueCookies.get('misc');

                if (cipherData === null  || cipherData === undefined || cipherData === '') {
                    window.location.href = "/login";
                } else {
                    bytes = CryptoJS.AES.decrypt(cipherData, process.env.VUE_APP_DCRPT_KEY);
                    decryptedData = bytes.toString(CryptoJS.enc.Utf8);

                    if (decryptedData) {
                        let decode = JSON.parse(decryptedData);
                        let token = decode.token;
          
                        //Check if user exists
                        if (token) {
                            let userData = {
                                user: currData,
                                token: token
                            }
                            
                            //Update Cookies
                            let encryptData = CryptoJS.AES.encrypt(JSON.stringify(userData), process.env.VUE_APP_DCRPT_KEY).toString();
                            VueCookies.set("misc", encryptData, '1d', null, null, true, "Strict");
                        } else {
                            localStorage.setItem('is_session_expired', true);
                            localStorage.removeItem("is_consent_agreed");
                            VueCookies.remove("misc");
                            VueCookies.remove("misc-hs");
                            window.location.href = '/login';
                        }
                    } else {
                        localStorage.setItem('is_session_expired', true);
                        localStorage.removeItem("is_consent_agreed");
                        VueCookies.remove("misc");
                        VueCookies.remove("misc-hs");
                        window.location.href = '/login';
                    }
                }

                setTimeout(function() {
                    that.openToast(`<b>${res.data.message}</b>`, 'success')
                }, 1000);
                
                this.$router.push('/dashboard')
            }).catch((err) => {
                this.dismissLoading();

                let errorFields = '';
                let errorMessage = err.data.errors;

                for (let key in errorMessage) {
                    errorFields += errorMessage[key][0] + '<br>';
                }

                const that = this;

                setTimeout(function() {
                    that.openToast('<b>Sorry, Invalid fields:</b><br><br>' + errorFields, 'danger')
                }, 1000);
            });
        },
        async openToast(msg, type) {
            const toast = await toastController
                .create({
                    message: msg,
                    animated: true,
                    duration: 5000,
                    color: type,
                    cssClass: 'toast-custom',
                    end: true
                })
            return toast.present();
        },
        async logoutDialog() {
            const alert = await alertController.create({
                cssClass: 'alert-container',
                header: 'Logout User',
                backdropDismiss: false,
                message: 'Are you sure you want to logout? Any unsaved changes will be lost.',
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                        },
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            this.logout();
                        },
                    },
                ],
            });

            return alert.present();
        },
        async logout() {
            const that = this;
            this.presentLoading();

            await store.dispatch('user/logout').then(() => {
                localStorage.removeItem("is_consent_agreed");
                VueCookies.remove('misc');
                VueCookies.remove("misc-hs");
                that.dismissLoading();

                setTimeout(function() {
                    window.location.href = '/login';
                    that.openToast('Successfully logout', 'success')
                }, 1000);
            }).catch(() => {
                const that = this;

                that.dismissLoading();
                setTimeout(function() {
                    that.openToast('Something went wrong. Please try again', 'danger')
                }, 1000);
            });
        },
        populateData(parsed_user) {
            this.isPageLoading = false;
            this.userInfo = parsed_user;
            this.emailAddress = parsed_user.email;
            this.firstName = parsed_user.first_name;
            this.lastName = parsed_user.last_name;
            this.middleName = parsed_user.middle_name;
            this.suffix = parsed_user.suffix;
            this.picture = parsed_user.picture;
        }
    }
}
</script>
<style scoped>
    .title-container {
        padding: 25px 11px;
        
        background: rgb(2,0,36);
        background: linear-gradient(130deg, rgba(2,0,36,1) 0%, rgba(26,39,145,1) 35%, rgba(0,224,255,1) 100%);
    }
  
    .title-container h1 {
        font-weight: bolder;
        margin-top: 0;
    }

    .title-content-avatar {
        margin: auto 15px;
        margin-left: 0;
    }

    .title-content-avatar-image {
        border: 3px solid white;
        background: #FFF;
        width: 85px;
        height: 85px;
    }

    .title-content-subheader {
        margin-bottom: 5px;
    }

    .title-content-name {
        font-family: "Roboto Bold";
        font-size: 20px;
    }

    .title-text-only {
        display: flex;
        flex-direction: row;
        padding: 0;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .title-content-notice {
        font-family: "Roboto Medium";
    }

    .title-content-text {
        color: #FFF;
    }

    .radio-container {
		display: flex;
		flex-direction: row;
	}

    .radio-button,
	.radio-icon {
		margin-right: 5px;
	}

    .input-item-custom::part(native) {
		padding-left: 0;
	}

    .button-container {
        padding: 0 11px;
    }

    .card-action-container {
        display: block;
    }

    .card-action-title {
        font-size: 18px;
    }

    .card-action-button { 
        text-align: left;
    }

    .onboard-input {
        border-bottom: 1px solid black;
        padding-left: 0 !important;
        padding-right: 0 !important;
        font-family: "Roboto Regular";
    }

    .onboard-input .native-input {
        padding-left: 0 !important;
        padding-right: 0 !important;
        font-family: "Roboto Regular";
    }

    .button-container {
        padding: 0;
    }

    .content-card-title h1 {
        margin: 10px 0;
        font-weight: bolder;
        color: #000;
        font-family: "Roboto Bold";
        font-size: 18px;
    }

    .content-card-select::part(native) {
        padding: 0 !important;
        margin: 0 !important;
        font-family: "Roboto Regular";
        border-bottom: 1px solid #000;
    }

    .content-card-data {
        font-size: 18px;
    }

    .content-card-select .content-card-dropdown {
        padding-left: 0;
        width: 100%;
        max-width: 100%;
    }

    .button-container {
        text-align: right;
    }

    .content-container-subtitle {
        padding: 5px 15px;
    }

    .content-container-subtitle h3 {
        font-size: 18px;
    }

    .content-name-container {
        display: block;
        padding: 0 5px;
    }

    @media (min-width: 768px) {
        .content-container-subtitle {
            padding: 5px 0;
        }

        .content-container-subtitle h3 {
            font-size: 20px;
        }

        .content-container-card,
        .button-container,
        .title-content,
        .content-container-subtitle {
            width: 700px;
            margin: 25px auto;
        }

        .title-content {
            display: flex;
            flex-direction: row;
        }
        
        .title-content-name,
        .content-card-title h1 {
            font-size: 26px;
        }

        .content-card-data,
        .card-action-title {
            font-size: 20px;
        }

        .card-action-container,
        .content-name-container {
            display: flex;
        }

        .content-name-container { 
            padding: initial;
        }

        .card-action-button { 
            text-align: right;
        }
    }
</style>